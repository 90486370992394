
import './App.css';
import Form from './components/Form';
import Partners from './components/Partners';
import Descarga from './components/Descarga';
import Cuotas from './components/Cuotas';
import Footer from './components/Footer';
import Hero from './components/Hero'

function App() {
  return (
    <div className="App">

         <Hero/>
       
         <div className='xs:mt-40 mt-24'>
         <Form/>
        </div>  
          
         <div className='xs:mt-52 mt-24'>
        <Partners/>
         </div>

        <div className='pb-24 mb-20 xs:mb-26 bg-fondo'>
        <Descarga />

            <div className='mt-52'>
            <Cuotas/>
           </div>
        </div>         
          
    
        
         <Footer/>  
 

        </div>
  );
}

export default App;