import black from '../assets/Black.svg'
import info from '../assets/Info.png'
import icon from '../assets/Icon.png'
import { useState,useEffect } from 'react';

import axios from 'axios'

const Form = () =>{
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    dni: '',
    telefono: '',
    direccion: '',
    mail: '',
    empresa:''
  });
 
  const [isOpen, setIsOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);
  const [showErrors,setShowErrors] = useState(false)
  const [isClosing, setIsClosing] = useState(false)

  const handleLogout = () => {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false)
      }, 350); // Espera 0.5 segundos para ocultar el componente después de la animación
    }; 



  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  }; 

  
  // Función para verificar si hay números ascendentes
  function hasAscendingNumbers(digits) {
    let consecutiveCount = 1;
  
    for (let i = 0; i < digits.length - 1; i++) {
      const currentDigit = digits[i];
      const nextDigit = digits[i + 1];
  
      if (currentDigit === nextDigit - 1) {
        consecutiveCount++;
  
        if (consecutiveCount > 3) {
          return true;
        }
      } else {
        consecutiveCount = 1;
      }
    }
  
    return false;
  }
  
  // Función para verificar si hay números descendentes
  function hasDescendingNumbers(digits) {
    let consecutiveCount = 1;
  
    for (let i = 0; i < digits.length - 1; i++) {
      const currentDigit = digits[i];
      const nextDigit = digits[i + 1];
  
      if (currentDigit === nextDigit + 1) {
        consecutiveCount++;
  
        if (consecutiveCount > 3) {
          return true;
        }
      } else {
        consecutiveCount = 1;
      }
    }
  
    return false;
  }
  
  // Función para verificar si hay números repetidos
  function hasRepeatedNumbers(digits) {
    let repeatCount = 1;
  
    for (let i = 0; i < digits.length - 1; i++) {
      const currentDigit = digits[i];
      const nextDigit = digits[i + 1];
  
      if (currentDigit === nextDigit) {
        repeatCount++;
  
        if (repeatCount > 3) {
          return true;
        }
      } else {
        repeatCount = 1;
      }
    }
  
    return false;
  }

  const validate = (formData) => {
    let errors = {};
  
    if (!formData.nombre) {
      errors.nombre = 'El nombre es requerido';
    }
  
    if (!formData.apellido) {
      errors.apellido = 'El correo electrónico es requerido';
    }
   
    if (!formData.dni) {
      errors.dni = 'El DNI es requerido';
    } else {
      const dniRegex = /^\d{7,8}$/;
      if (!dniRegex.test(formData.dni)) {
        errors.dni2 = 'Ingresá un DNI válido';
      } else {
        const dniDigits = formData.dni.split('').map(Number);
    
        if (
          // hasConsecutiveNumbers(dniDigits, 4) ||
           hasAscendingNumbers(dniDigits) ||
           hasDescendingNumbers(dniDigits) ||
           hasRepeatedNumbers(dniDigits)
        ) {
          errors.dni3 = 'Ingresá un DNI válido';
        }
      }
    }
   
    
    if (!formData.telefono) {
      errors.telefono = 'El teléfono es requerido';
    }else {
      const telregex = /^\d{8,12}$/;
      if (!telregex.test(formData.telefono)) {
        errors.telefono2 = 'Revise su numero telefónico'; 
      } else {
        const dniDigits = formData.telefono.split('').map(Number);
    
        if (
          // hasConsecutiveNumbers(dniDigits, 4) ||
           hasAscendingNumbers(dniDigits) ||
           hasDescendingNumbers(dniDigits) ||
           hasRepeatedNumbers(dniDigits)
        ) {
          errors.telefono3 = 'Ingresá un teléfono válido';
        }
      }} 

    if(!formData.direccion){
      errors.direccion ='La direccion es requerida'
    }
    
    if (!formData.mail) {
      errors.mail = 'El correo electrónico es requerido';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.mail)) {
        errors.mail2 = 'El correo electrónico no es válido';
      }
    }

  if (!formData.empresa) {
    errors.empresa = 'Selecciona una opcion'
  }


    return errors;
  }

  useEffect(() => {
    setErrors(validate(formData));
  }, [formData]);
   

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };
  
 

  const handleSubmit = (e) => {
    e.preventDefault();

    
    if (Object.keys(errors).length > 0) {
      setShowErrors(true); 
   } else {
     try{
       axios.post('https://financiamiento.blackwallet.com.ar:6474/emailSender', formData).then(
         setIsOpen(true))
         setFormData({
           nombre: '',
           apellido: '',
           dni: '',
           telefono: '',
           direccion: '',
           mail: '',
           empresa:''
         })
         setShowErrors(false)
  }catch(e) {
    console.log(e)
  }
  

     }
  };
 

  
 
  




 return (
 
    <div className='flex xs:flex-row flex-col'>

{isOpen && ( <div className='bg-black modal-container font-Ubuntu'>
  <div className={`modal border ${isClosing ? 'slide-out' : 'slide-in'} border-white`}>
    <button className='w-full flex justify-end pr-4 mt-2' onClick={() => handleLogout()}>x</button>
    <div className='flex flex-col px-16 py-12 justify-center items-center'>
      <div className='bg-bgButton rounded-full w-[4.2rem] h-[4.2rem] flex items-center justify-center'>
       <img src={icon} alt='icon'/>
       </div> 
     <h1 className='font-bold text-[40px]'>Recibimos tu información</h1>
     <h3>A la brevedad nos pondremos en contacto.</h3>
   
   </div>
  </div> 


</div>) }


    <div className='flex flex-col xs:mb-0 mb-6 xs:w-[40%] xs:pl-16 pl-4 xs:px-0  justify-center items-center' >
   
     <div className='text-black font-bold xs:leading-[4.5rem] leading-[1.2em] font-Ubuntu text-[40px] xs:text-[60px]'>
        <h1>Empezá tu </h1>
        <h1>financiación</h1>
       
        <div className='flex flex-row mb-4'>
        <span className='pr-5'>con</span>
        <img src={black} alt='black' className='w-[50%] xs:w-full'/>
        </div>
     </div> 
     
     <p className='font-medium w-[90%] font-Ubuntu xs:w-full sm:w-[90%] ss:w-[100%] md:w-[50%]  px-2'>Completá el formulario para que nos pongamos en contacto y empecemos con tu plan de financiación personalizada.</p>
        
    </div>
 

<div className='xs:w-[60%] flex justify-center relative'>
   <div className='bg-black xs:w-[60%] w-[88%] text-white font-Ubuntu p-8 rounded-2xl'>
     <div className='flex flex-col'>
        <h1 className='font-medium mb-5 text-[26px]'>Completá con tu información</h1>
        <div className='flex flex-row'>
           <h3 className='font-light mb-10 mr-1'>Recordá que podemos solicitarte más información vía mail</h3>
           <img src={info} className='w-6 h-6' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} alt='info'/>
           {showTooltip && (
            <div className='bg-bgButton text-white text-[15px] absolute z-40 top-[20%] right-[15%] font-Ubuntu w-[55%] xs:w-[40%] rounded-xl px-8 xs:px-12 py-4'>
                 <h1 className='font-medium xs:text-[20px] text-[15px] mb-2 xs:mb-6'>Tené a mano:</h1>
                 <ul className='list-disc  text-[13px] xs:text-[13px] space-y-4 xs:space-y-2'>
                  <li>Foto del DNI del solicitante, frente y dorso.</li>
                  <li>Últimos tres recibos de sueldo y/o últimos seis pagos del monotributo.</li>
                  <li>Constancia de domicilio y/o servicio a nombre del solicitante.</li>
                 </ul>
            </div>
          )}
        </div>

     
     
     
    <form className='flex flex-col font-normal' autoComplete='off' onSubmit={handleSubmit}>

    <div className='w-full mb-10 relative'>
       
       <label className='labelS px-1 pb-3 text-[12px]'  >Finacia con</label>
   
         <select name='empresa'onChange={handleInputChange}
         className={`bg-black border border-white w-full ${formData.empresa === '' ? 'text-gray-400' : 'text-white'} rounded-lg  p-3`}  > 
          <option  disabled={true} value='' selected={true}>¿Con quién queres financiar?</option>
          <option className='text-white' > Persona particular </option>
          <option className='text-white' value='Test Viajes'>Test viajes</option>
          <option className='text-white' value='Club Atlético Rosario Central'>Club Atlético Rosario Central</option>
          <option className='text-white' value='Club Atlético Banfield'>Club Atlético Banfield</option>
         </select>
     {showErrors === true ? errors.empresa && (<p style={{color:'red', fontSize:'10px'}}>{errors.empresa}</p>): null }
     </div>  

       <div className='flex xs:flex-row flex-col mb-10'>
           
           <div className='flex flex-col mr-4 xs:mb-0 mb-10 xs:w-[49%] w-full relative'>
             <label className='bg-black text-[12px] labelS px-1'>Nombre/s</label>
             <input placeholder='Nombre |' 
             name='nombre'
             value={formData.nombre}
             onChange={handleInputChange}
             className='bg-black border border-white rounded-lg p-3 w-[100%]'/>
               {showErrors === true ? errors.nombre && (<p style={{color:'red', fontSize:'10px'}}>{errors.nombre}</p>): null}
           </div>
         
           <div className='flex flex-col xs:w-[49%] w-full  relative'>
             <label className='labelS px-1 text-[12px]'>Apellido/s</label>
             <input 
             name='apellido'
             value={formData.apellido}
             onChange={handleInputChange}
             placeholder='Apellido |'  className='bg-black border border-white rounded-lg  p-3'/>
             {showErrors === true ? errors.apellido && (<p style={{color:'red', fontSize:'10px'}}>{errors.apellido}</p>): null}
           </div>
        
        </div>
        

        <div className='flex flex-row mb-10'>
         
         <div className='flex flex-col relative  mr-4 w-[49%]'>
           <label className='labelS px-1 text-[12px]'>DNI</label>
           <input placeholder='DNI |'  
           name='dni'
           class="autofill:bg-yellow-200 bg-black border border-white rounded-lg p-3"
           value={formData.dni}
           onChange={handleInputChange}
            />
             {showErrors === true ? errors.dni && (<p style={{color:'red', fontSize:'10px'}}>{errors.dni}</p>): null}
             {showErrors === true ? errors.dni2 && (<p style={{color:'red', fontSize:'10px'}}>{errors.dni2}</p>): null}
             {showErrors === true ? errors.dni3 && (<p style={{color:'red', fontSize:'10px'}}>{errors.dni3}</p>): null}
         </div>
         
         <div className='flex flex-col relative w-[49%]  '>
            <label className='labelS px-1 text-[12px]'>Teléfono</label>
            <input placeholder='Teléfono |'  
            name='telefono'
            value={formData.telefono}
            onChange={handleInputChange}
            className='bg-black border border-white rounded-lg  p-3'/>
              {showErrors === true ? errors.telefono && (<p style={{color:'red', fontSize:'10px'}}>{errors.telefono}</p>):null}
              {showErrors === true ? errors.telefono2 && (<p style={{color:'red', fontSize:'10px'}}>{errors.telefono2}</p>):null}
              {showErrors === true ? errors.telefono3 && (<p style={{color:'red', fontSize:'10px'}}>{errors.telefono3}</p>):null}
          </div>
        
        </div>

       <div className='mb-10 flex relative flex-col'>
         <label className='labelS text-[10px] px-1 '>Dirección(calle, altura, localidad, estado)</label>    
         <input placeholder='Dirección |'  
         name='direccion'
         value={formData.direccion}
         onChange={handleInputChange}
         className='bg-black border border-white rounded-lg  p-3'/>
           {showErrors === true ? errors.direccion && (<p style={{color:'red', fontSize:'10px'}}>{errors.direccion}</p>) : null}
       </div>

       <div className='flex flex-col mb-12 input-container relative'>
           <label className='labelS px-1 text-[12px]'>Mail</label>  
           <input placeholder='Mail |'  
           name='mail'
           value={formData.mail}
           onChange={handleInputChange}
           className='bg-black border border-white rounded-lg pb-2 p-3'/> 
             {showErrors === true ? errors.mail && (<p style={{color:'red', fontSize:'10px'}} >{errors.mail}</p>):null}
             {showErrors === true ? errors.mail2 && (<p style={{color:'red', fontSize:'10px'}} >{errors.mail2}</p>): null}

       </div> 
     
   


   <div className='flex justify-end'>
     <button type='submit' className={'bg-bgButton rounded-[1.4rem] font-medium w-32 h-12 hover:bg-[#16AC7D] transition-all duration-300'}>
      Enviar</button>
   </div> 
  
  
  </form>
     
        
</div>

</div> 

    

</div>


</div>
 )
}


export default Form