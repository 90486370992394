
import phone from '../assets/celu.png'
import google from '../assets/google2.svg'
import appstore from '../assets/appstore.svg'

const Descarga = () => {

     return (
        <div className="flex xs:flex-row flex-col mt-24 z-10">
              
          <div className='xs:w-[40%] w-full flex justify-end'>
            <img src={phone} alt='phone2'/>
          </div>
          
          <div className='flex flex-col font-Ubuntu xs:w-[60%] items-center justify-center'>
            
            <div className='flex flex-col leading-10 xs:mt-0 mt-4  xs:leading-[4rem] xs:w-[60%] items-start mb-8'>
            <h1 className='font-bold text-[40px] xs:text-[60px]'>Llegó tu nueva</h1>
            <h1 className='font-bold text-[40px] xs:text-[60px]'>wallet favorita</h1>
            </div>
            
            <div className='xs:w-[60%] w-[75%] font-Ubuntu  leading-5 mb-6'>
            <p className='font-medium items-start xs:pr-24 text-start text-[18px] flex '>Descargate la app y comenzá a disfrutar todos los
           beneficios que tenemos para vos.</p>
            </div>
           
           <div className='flex flex-row items-start xs:w-[60%] mr-6 xs:mr-2'>
              <a href='https://play.google.com/store/apps/details?id=app.blackwallet.ar&pli=1' target='blank'>
              <img src={appstore} alt='appstore' className='w-[134px] h-[36px]' />           
              </a>

              <a href='https://apps.apple.com/us/app/black-wallet/id1661314614' target='blank'>
              <img src={google} alt='google' className='w-[134px] h-[36px]'/>
               </a>
          
           </div>
             
          </div>
 
         

        </div>
     )
}


export default Descarga