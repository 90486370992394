
import venus from '../assets/Venus.png'
import { useMediaQuery } from 'react-responsive'
import circulo from '../assets/circulo.svg'

const Hero = () => {

    const isMobile = useMediaQuery({ maxWidth: 480 }); // Para solo smartphones

 return (
    

    isMobile ? <div className="xs:h-[80vh] relative flex flex-col  prueba2   bg-blackBg rounded-b-[60px]">
        
    {/* <div className="bg-white ss:bg-green-300 md:bg-red-600 xs:bg-yellow-400 sm:bg-blue-600 "></div> */}
   
   

   <div className="sm:mb-24 md:mb-32 mt-4 ss:mb-12 flex items-center justify-center">
     <img className="ss:w-[130px] w-[90px] ss:h-[76px]" src={venus} alt="logo" />
   </div>
   <div className="xs:w-[50%] px-12 flex items-end mt-44">
     {/* <img src={hash} className="w-full hash-image" alt='hash' /> */}
     
     <div className="flex flex-row mt-72 xs:mt-0  md:mt-8 mb-28">
       <div className="xs:w-[90%] xs:px-12">
         <h1 className="md:ml-[20%]  mb-6 text-white font-Ubuntu font-medium xs:text-[20px]">Somos una fintech de alcance nacional que nació para brindarte oportunidades. Llegamos para demostrar que lo que creías inalcanzable, ahora es para vos.</h1>
         <h1 className="md:ml-[20%] text-white font-Ubuntu font-medium xs:text-[20px]">Queremos que te sientas orgulloso de tener tu Black, así como nosotros también nos sentimos orgullosos de verte cumplir tus deseos. </h1>
       </div>
      
        <div className=''>
        {/* <img src={phone} className="phone-image w-[28%]  " alt="phone" /> */}
       </div> 
     </div>
</div> 

<div className='w-full flex items-center justify-center absolute bottom-[-5%] '>
      <div className='bg-black rounded-full flex justify-center items-center w-[6rem] h-[6rem]'>
      <img src={circulo} className='w-[4.8rem] h-[4.8rem]' alt='circulo'/>
      </div>
    </div> 
</div>

: 
    
    <div className="xs:h-[80vh] flex flex-col  prueba  relative bg-blackBg rounded-b-[60px]">
        
      {/* <div className="bg-white ss:bg-green-300 md:bg-red-600 xs:bg-yellow-400 sm:bg-blue-600 "></div> */}
     <div className="sm:mb-24 md:mb-32 mt-4 ss:mb-12 flex items-center justify-center">
       <img className="ss:w-[130px] w-[90px] ss:h-[76px]" src={venus} alt="logo" />
     </div>
     <div className="xs:w-[64%] md:w-[50%] px-12 flex items-end mt-44 xs:mt-56 lt:mt-44 md:mt-44">
       {/* <img src={hash} className="w-full hash-image" alt='hash' /> */}
       
       <div className="flex flex-row xs:mt-0 mt-2 md:mt-8 mb-28">
         <div className="xs:w-[90%] xs:px-12">
           <h1 className="md:ml-[20%]  mb-6 text-white font-Ubuntu font-medium xs:text-[18px] ss:text-[20px]">Somos una fintech de alcance nacional que nació para brindarte oportunidades. Llegamos para demostrar que lo que creías inalcanzable, ahora es para vos.</h1>
           <h1 className="md:ml-[20%] text-white font-Ubuntu font-medium xs:text-[18px] ss:text-[20px]">Queremos que te sientas orgulloso de tener tu Black, así como nosotros también nos sentimos orgullosos de verte cumplir tus deseos. </h1>
         </div>
        
          <div className=''>
          {/* <img src={phone} className="phone-image w-[28%]  " alt="phone" /> */}
         </div> 
       </div>
 </div>  

    <div className='w-full flex items-center justify-center absolute bottom-[-8%] '>
      <div className='bg-black rounded-full flex justify-center items-center w-[6.5rem] h-[6.5rem]'>
      <img src={circulo} className='w-[5.2rem] h-[5.2rem]' alt='circulo'/>
      </div>
    </div> 
 
 </div>

 )

}

export default Hero