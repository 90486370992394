import instagram from '../assets/Instagram.svg'
import Linkedin from '../assets/Linkedin.svg'
import Twitter from '../assets/Twitter.svg'
import Youtube from '../assets/Youtube.svg'
import Facebook from '../assets/Facebook.svg'


const Footer = () => {

  return (
    
    <div className="w-full text-white flex items-center justify-center flex-col xs:p-20 p-10 font-Ubuntu bg-black">
         
     <div className="flex flex-row items-center space-x-3 mb-6">
         <span className='text-white  text-[11px] xs:text-[18px]'>SEGUINOS EN</span>
         <a href='https://www.instagram.com/blackwallet.ar/' target='blank'>
         <img src={instagram} alt='instagram'/>
         </a>
         <a href='https://www.youtube.com/@blackwallet_ar' target='blank'>
         <img src={Youtube} alt='instagram'/>
         </a>
         <a href='https://www.facebook.com/blackwallet.ar' target='blank'>
         <img src={Facebook} alt='instagram'/>
         </a>
          <a href='https://twitter.com/Blackwallet_ar' target='blank'>
         <img src={Twitter} alt='instagram'/>
          </a>
       
         <a href='https://www.linkedin.com/company/black-wallet-ar/' target='blank'>
         <img src={Linkedin} alt='instagram'/>
         </a>
     </div>
     
     <a href='https://blackwallet.com.ar/' target='blank'>
     <h1>WWW.BLACKWALLET.COM.AR</h1>
     </a>
    </div>
  )

}


export default Footer