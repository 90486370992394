

import black from '../assets/BlackW.svg'
import tarjeta from '../assets/tarjeta.svg'

  const Cuotas = () => {
       
     return (
<div className='w-full flex xs:flex-row flex-col items-center justify-center z-10'>
  
  <div className="w-[80%] xs:h-[60vh] flex xs:flex-row flex-col bg-black rounded-2xl">


  <div className='flex flex-col xs:w-[60%] justify-center xs:pl-24 pl-8 xs:pt-0 pt-12 xs:pb-16 '>
   
   <div className='flex items-start flex-col w-full'>

     <div className='text-white xs:font-bold font-medium xs:leading-[4rem] leading-[2rem] mb-4 font-Ubuntu sm:text-[50px] md:text-[70px] text-[30px]'>
          <h1>Pagá en cuotas</h1>
     
           <div className='flex flex-row mb-4'>
            <span className='pr-5'>con</span>
            <img className='xs:w-60 w-24' src={black} alt='black'/>
          </div>
     </div> 

    <p className='font-medium md:pr-48 xs:mb-16 font-Ubuntu  mb-6 text-white xs:text-[20px] text-[14px] '>Comprá con débito y crédito en comercios, locales y tiendas online. Además podés suscribirte a tus plataformas favoritas.</p>


    <div className='flex justify-end'>
      <a href='https://blackwallet.com.ar/' target='blank'>
      <button className='bg-bgButton text-white rounded-[1.4rem] font-Ubuntu font-medium xs:w-44 w-28 h-12 hover:bg-[#16AC7D] transition-all duration-300'>Conocé más</button>
      </a>
    </div>    
   </div>
 </div>
  
  <div className='xs:w-[60%] flex justify-end mt-4 sm:mt-0 items-center'>
    <img src={tarjeta} className='xs:mr-[-2.9%] mr-[-3%] xs:mt-0 mt-[-10%] w-full' alt='tarjeta'/>  
  </div>

 </div>
 </div>    
     )
  }


  export default Cuotas