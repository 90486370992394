
import test from '../assets/test.png'
import carc from '../assets/carc.svg'
import banf from '../assets/banfield.svg'


const Partners = () => {

       return (
        <div className="w-full flex items-center flex-col">
             <h1 className='font-Ubuntu xs:font-[500] font-[500] xs:mb-12 mb-8'>NUESTROS PARTNERS</h1>
        
        <div className="flex xs:mr-0 flex-row xs:space-x-24 space-x-12">
          <div className='se:w-[90px]  xs:w-[150px] flex items-center justify-center'>
            <img src={test} className='' alt='test'/>
          </div>
             
              <div className='se:w-[90px] xs:w-[150px] flex items-center justify-center '>
                 <img src={carc} className='' alt='rosario'/>
              </div>
           
            <div className='se:w-[90px]  xs:w-[150px] flex items-center justify-center '>
            <img src={banf} className='' alt='banfield' />
            </div>
        </div>
        
        
        </div>
       )

}


export default Partners